<template>
  <div class="calendar">
    <div class="calendar-box">
      <input
        class="inputSty"
        type="text"
        :value="chooseDate"
        placeholder="选择日期"
        @focus="hFocus"
      />
      <div class="calendar-body" v-show="isShowCon">
        <div class="calendar-body-text">
          <div
            style="cursor: pointer; position: absolute; left: 0"
            @click="month--"
          >
            《
          </div>
          <div class="text-title">{{ timeValue }}</div>
          <div
            style="cursor: pointer; position: absolute; right: 0"
            @click="month++"
          >
            》
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th v-for="(h, i) in teheadList" :key="i">{{ h }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(b, i) in tbodyList" :key="i">
              <td
                v-for="(o, index) in b"
                :key="index"
                :class="{ activeDay: currentDay == o.month + '-' + o.name }"
                @click="chooseData(o)"
              >
                <span :class="o.class" v-if="o.name == 'default'">{{
                  o.name
                }}</span>
                <span
                  :class="{
                    currentMonth: day == o.name && foreverMonth == o.month,
                    default:
                      o.class == 'default' ||
                      foreverMonth > o.month ||
                      (o.month == foreverMonth && o.name < day),
                  }"
                  
                  v-else
                  >{{ o.name }}</span
                ><!-- @click="currentDay = o.month + '-' + o.name" -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "calendar",
  data: () => {
    return {
      timeValue: "",
      teheadList: ["日", "一", "二", "三", "四", "五", "六"],
      year: "",
      month: 0,
      foreverMonth: 0,
      day: "",
      tbodyList: [],
      currentDay: "",
      chooseDate: "",
      isShowCon: false,
    };
  },
  mounted() {
    this.getInitTime();
  },
  watch: {
    month() {
      if (this.month < 1) {
        this.month = 12;
        this.year--;
      } else if (this.month > 12) {
        this.month = 1;
        this.year++;
      }
      this.tbodyList = [];
      // this.timeValue = this.year + '年' + this.month + '月'
      this.timeValue = this.month + "月";
      this.handleAetCalendar();
    },
  },
  methods: {
    hBlur() {
      this.isShowCon = false;
    },
    hFocus() {
      this.isShowCon = true;
    },
    chooseData(o) {
      if (
        !(this.foreverMonth > o.month || (o.month == this.foreverMonth && o.name < this.day))
      ) {
        this.chooseDate = o.month + "-" + o.name;
        this.currentDay = o.month + '-' + o.name
        this.hBlur();
        console.log(o);
        let data = {
          month: o.month,
          day: o.name,
          isSelecting: false,
        };
        this.$emit("deliverDate", data);
      }
    },
    getInitTime() {
      let date = new Date();
      this.year = date.getFullYear(); // 获取当年
      this.month = date.getMonth() + 1; // 获取本月
      this.foreverMonth = date.getMonth() + 1;
      this.day = date.getDate(); // 获取当天
      this.timeValue = date.getMonth() + 1 + "月";
      // this.timeValue = date.getFullYear() + '年' + (date.getMonth()+1) + '月'

      this.handleAetCalendar();
    },
    // 生成档期数据
    handleAetCalendar() {
      this.tbodyList = [];
      let days = new Date(this.year, this.month, 0).getDate(); // 当月总天数
      let week = new Date(this.year, this.month - 1, 1).getDay(); // 当月有几周
      let last_month = new Date(this.year, this.month + 1, 0).getDate(); // 当月的上一个月的最后一天
      this.tbodyList[0] = [];
      for (let i = 0; i < Math.ceil((days + week) / 7) * 7; i++) {
        let nub = Math.floor(i / 7);
        if (i < week) {
          this.tbodyList[nub].push({
            class: "default",
            name: last_month + i - week + 1,
            month: this.month == 0 ? 12 : this.month - 1,
          });
        } else {
          if (!this.tbodyList[nub]) {
            this.tbodyList[nub] = [];
          }
          let day = i - week + 1;
          let className = "actives";
          let month = this.month;
          if (day > days) {
            day -= days;
            className = "default";
            month = this.month + 1 > 12 ? 1 : this.month + 1;
          }
          this.tbodyList[nub].push({
            class: className,
            name: day,
            month: month,
          });
        }
      }
      let arr = this.tbodyList[Math.floor((week + days) / 7)];
      if (arr && arr.length !== 7) {
        this.tbodyList[Math.floor((week + days) / 7)] = arr.concat(
          new Array(7 - arr.length).fill("")
        );
      }
      console.log(this.tbodyList);
    },
  },
};
</script>

<style lang="less" scoped>
.calendar {
  z-index: 9999;
  width: 206px;
  // height: 400px;
  // background: #DCDCDC;
  // padding: 15px;
  margin: 50px auto;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.12);
  .calendar-box {
    z-index: 9999;
    width: 100%;
    background: #fff;
    height: 100%;
    position: relative;
    .inputSty {
      width: 100%;
      height: 34px;
      background: none;
      outline: none;
      border: 1px solid #dcdfe6;
      padding-left: 7px;
    }
    .calendar-body {
      width: 100%;
      margin: 0 auto;
      padding: 7px;
      .calendar-body-text {
        width: 100%;
        height: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
        .text-title {
          margin: 0 20px;
        }
      }
    }
    table tr th {
      color: #ccc;
    }
    table tr th,
    table tr td {
      width: 28px;
      height: 33px;
      text-align: center;
    }
    table td {
      &.activeDay {
        // border-bottom: 1px solid red;
        span {
          background: #46a0fc;
          color: #fff;
          border-radius: 50%;
          width: 19px;
          text-align: center;
          display: inline-block;
          box-sizing: border-box;
        }
      }
      .currentMonth {
        color: #00bfff;
      }
    }
    table td span {
      color: #333;
      cursor: pointer;
    }
    table td span.default {
      color: #e9e9e9;
    }
  }
}
</style>
