<template>
  <div style="width: 100%">
    <div class="container">
      <!-- 头部 -->
      <div class="header">
        <router-link to="/"><img src="../../static/img/Vector.png" alt="" /></router-link>
        <p>停车位预约</p>
      </div>
      <!-- 小车图片 -->
      <div class="headerImage">
        <img class="backgroundImage" src="../../static/img/ParkingBackground.png" alt="">
        <img class="car" :class="{'activeCar' : selectedTime!= null && selectedSpace != null}" src="../../static/img/car.png" alt="">
        <img class="chargingPile" :class="{'activeChargingPile' : selectedTime!= null && selectedSpace == 1}" src="../../static/img/ChargingPile.png" alt="">
      </div>
      <!-- 第一步 -->
      <first-step style="transition: all 0.5s; position: absolute;" :class="{ 'translate-100' : NextStep}" @getSelectTime="getSelectTime" @getSelectSpace="getSelectSpace" @toTheNextStep="toTheNextStep" :NS="NextStep" @getSelectedDate="getSelectedDate"/>
      <!-- 第二步 -->
      <second-step style="transition: all 0.5s; position: absolute;" class="toSecondStep" v-show="NextStep" @backToFirstStep="backToFirstStep" @sendAppointment="sendAppointment" />
    </div>
  </div>
</template>
<script>

import FirstStep from './firstStep.vue';
import SecondStep from './secondStep.vue';
import qs from 'qs';

export default {
  components: {
    FirstStep,
    SecondStep,
  },
  data() {
    return {
      selectedTime: null,
      selectedSpace: null,
      NextStep: false,
      translate0: 'translateX(0)',
      translateX100: 'translateX(100)',
      finalData:{},
      date: "",
    };
  },
  methods: {
    getSelectTime(data) {
      this.selectedTime = data;
    },
    getSelectSpace(data) {
      this.selectedSpace = data;
      this.finalData["car_type"] = data + '';
    },
    getSelectedDate(data) {
      this.date = data;
    },
    toTheNextStep(data) {
      this.NextStep = data;
    },
    backToFirstStep(data){
      this.NextStep = data;
    },
    sendAppointment(data) {
      this.finalData = data;
      this.finalData["car_type"] = this.selectedSpace == 0 ? "1" : "0";
      this.finalData["time"] = this.selectedTime;
      this.finalData["date"] = this.date;
      console.log(this.finalData)
      this.$axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/Parking/insertNewParking',
        data: qs.stringify(this.finalData),
      }).then(
        (res) => {
          console.log(res)
          if(res.data.msg == "数据已新增。"){
            this.$router.push({
              name:'PRSuccess',
              params: {
                data: res.data.data.newParking,
              },
            })
          }else{
            console.log("FAIL!!!")
            this.$router.push({
              name:'PRFail',
              params: {
                data: res.data,
              },
            })
          }
        }
      )
      
    },
  },
  mounted() {
  },

};

</script>
<style scoped>
@keyframes translate100to0{
  from{
    transform: translate(100%);
  }
  to{
    transform: translate(0);
  }
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.container{
  position: relative;
  transition: all 0.5s;
}

.header {
  position: relative;
  background-color: #1f89eb;
  height: 50px;
  width: 100%;
  margin-bottom: 20px;
}

.header  img {
  position: absolute;
  left: 10%;
  top: 50%;
  transform: translate(0%, -50%) rotateY(180deg);
  height: 16px;
}

.header > p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #FFFFFF;
}

.headerImage{
  position: relative;
  height: 172.88px;
  width: 80%;
  margin-left: 10%;
  margin-bottom: 40px;
  overflow: hidden;
}

.backgroundImage{
  position: absolute;
  width: 100%;
  height: 172.88px;
  z-index: -3;
}

.car{
  position: absolute;
  bottom: -8px;
  right: 0px;
  width: 79.593%;
  z-index: -2;
  transition: all 0.5s;
  opacity: 0;
  transform: translateX(-100%);
}

.activeCar{
  opacity: 1;
  transform: translateX(0);
}

.chargingPile{
  position: absolute;
  bottom: 2px;
  left: 0px;
  width: 31.496%;
  z-index: -1;
  opacity: 0;
  transition: all 0.5s;
}

.activeChargingPile{
  opacity: 1;
}

.toSecondStep{
  animation: translate100to0 0.5s ease-in-out;
}

.translate-100{
  transform: translateX(-100%);
}

.translate0{
  transform: translateX(0);
}

.translate100{
  transform: translateX(100%);
}
</style>
