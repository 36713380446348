<template>
  <div style="width: 100%">
    <div class="container">
      <div class="parkDate">
        <p class="BarTitle">停车日期：</p>
        <p class="date" v-if="dateSelected" @click="selectDate">
          {{ year }} 年 {{ month }} 月 {{ day }} 日
        </p>
        <p class="date" v-else @click="selectDate" style="color: #1f89eb;">选择日期</p>
        <calender v-if="selectingDate" @deliverDate="deliverDate" />
        <div class="filter" v-if="selectingDate"></div>
        <div class="parkTime">
          <span
            class="selectableItem"
            :class="{
              disableItem: !item.selectable,
              selectedItem: index == selectedTime,
            }"
            v-for="(item, index) in parkTimes"
            :key="item.time"
            :data-id="index"
            @click="selectTime($event, index)"
            >{{ item.time }}</span
          >
        </div>
        <div class=""></div>
      </div>
      <div class="parkingSpaceSelect">
        <p class="BarTitle">车位选择：</p>
        <div class="parkingSpace">
          <span
            class="selectableItem"
            :style="{
              background: index == 1 && item.selected ? '#09A6B5' : '',
            }"
            :class="{
              disableItem: !item.selectable,
              selectedItem: index == selectedSpace,
            }"
            v-for="(item, index) in parkingSpaces"
            :key="item.space"
            :data-id="index"
            @click="selectSpace($event, index)"
            >{{ item.space }}</span
          >
        </div>
      </div>
      <div
        @click="toTheNextStep"
        class="nextStepBtn"
        :class="{ disableItem: selectedTime == null || selectedSpace == null || !this.dateSelected}"
      >
        下一步
      </div>
    </div>
  </div>
</template>
<script>
import Calender from "../../components/Calender.vue";
import qs from 'qs';

export default {
  components: { Calender },
  props: ["NS"],
  data() {
    return {
      day: "",
      month: "",
      year: "",
      parkTimes: [
        {
          time: "8:00 - 12:00",
          selectable: true,
          selected: false,
          petrol: 0,
          newEnergy: 4,
        },
        {
          time: "12-00 - 16:00",
          selectable: true,
          selected: false,
          petrol: 0,
          newEnergy: 4,
        },
        {
          time: "16:00 - 20:00",
          selectable: true,
          selected: false,
          petrol: 0,
          newEnergy: 4,
        },
        {
          time: "20:00 - 23:00",
          selectable: true,
          selected: false,
          petrol: 0,
          newEnergy: 4,
        },
        /* {
          time: "15:00 - 16:00",
          selectable: true,
          selected: false,
          petrol: 4,
          newEnergy: 2,
        },
        {
          time: "16:00 - 17:00",
          selectable: true,
          selected: false,
          petrol: 4,
          newEnergy: 2,
        }, */
      ],
      parkingSpaces: [
        {
          space: "普通车位剩余0个",
          selectable: true,
          selected: false,
        },
        {
          space: "充电车位剩余0个",
          selectable: true,
          selected: false,
        },
      ],
      dateSelected: false,
      selectingDate: false,
      selectedTime: null,
      selectedSpace: null,
      NextStep: this.NS,
    };
  },
  methods: {
    getCurrentTime() {
      this.year = new Date().getFullYear();
      /* this.month = new Date().getMonth() + 1;
      this.day = new Date().getDate(); */
    },
    selectDate() {
      this.selectingDate = true;
    },
    selectTime(e, index) {
      if (this.selectedTime === index) {
        this.selectedTime = null;
        this.parkTimes[index].selected = false;
      } else if (this.parkTimes[index].selectable != false) {
        this.selectedTime = index;
        let pT = [].concat(this.parkTimes);
        Object.keys(pT).forEach((key) => {
          pT[key].selected = false;
        });
        pT[index].selected = true;
        this.parkTimes = pT;
        this.parkingSpaces[0].space =
          "普通车位剩余" + this.parkTimes[index].petrol + "个";
        this.parkingSpaces[1].space =
          "充电车位剩余" + this.parkTimes[index].newEnergy + "个";
        this.parkTimes[index].petrol == 0
          ? (this.parkingSpaces[0].selectable = false)
          : (this.parkingSpaces[0].selectable = true);
        this.parkTimes[index].newEnergy == 0
          ? (this.parkingSpaces[1].selectable = false)
          : (this.parkingSpaces[1].selectable = true);
      }
      this.$emit("getSelectTime", this.selectedTime);
      this.selectedTime;
    },
    selectSpace(e, index) {
      if (this.selectedSpace === index) {
        this.selectedSpace = null;
        this.parkingSpaces[index].selected = false;
      } else if (this.parkingSpaces[index].selectable != false) {
        this.selectedSpace = index;
        let pS = [].concat(this.parkingSpaces);
        Object.keys(pS).forEach((key) => {
          pS[key].selected = false;
        });
        pS[index].selected = true;
        this.parkingSpaces = pS;
      }
      this.$emit("getSelectSpace", this.selectedSpace);
      this.selectedSpace;
    },
    toTheNextStep() {
      if (this.selectedTime != null && this.selectedSpace != null && this.dateSelected) {
        this.NextStep = !this.NextStep;
        this.$emit("toTheNextStep", this.NextStep);
      }
    },
    getDailyParking() {
      this.$axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/Parking/getDailyParking',
        data: qs.stringify({date: this.year + '-' + this.month + '-' + this.day}),
      }).then((res) => {
        let data = res.data.data;
        console.log(res.data.data);
        for (let i = 0; i < data.length; i++) {
          this.parkTimes[i].petrol = data[i].petrol;
          this.parkTimes[i].newEnergy = data[i].newEnergy;
          data[i].petrol == 0 && data[i].newEnergy == 0
            ? (this.parkTimes[i].selectable = false)
            : "";
        }
        this.parkingSpaces[0].space = "普通车位剩余" + data[0].petrol + "个";
        this.parkingSpaces[1].space = "充电车位剩余" + data[0].newEnergy + "个";
        data[0].petrol == 0 ? (this.parkingSpaces[0].selectable = false) : "";
        data[0].newEnergy == 0
          ? (this.parkingSpaces[1].selectable = false)
          : "";
      });
    },
    deliverDate(data) {
      this.selectingDate = data.isSelecting;
      this.month = data.month < 10 ? '0' + data.month : data.month;
      this.day = data.day < 10 ? '0' + data.day : data.day;
      this.dateSelected = true;
      this.getDailyParking();
      this.$emit("getSelectedDate", this.year + '-' + this.month + '-' + this.day)
    }
  },
  mounted() {
    this.getCurrentTime();
  },
  created() {
    this.getDailyParking();
  },
};
</script>
<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.container {
  position: relative;
  transition: all 0.5s;
}

.BarTitle {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #0e0e0e;
}

.date {
  position: absolute;
  top: 2px;
  right: 65px;
  display: inline-block;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #0e0e0e;
}

.parkDate {
  width: 80%;
  height: 200px;
  margin: auto;
  /* margin-bottom: 40px; */
  position: relative;
}

.filter {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9990;
  opacity: 0.4;
  background-color: black;
}

.parkTime {
  position: absolute;
  top: 50px;
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  /* align-content: space-between; */
}

.selectableItem {
  width: 45%;
  height: 40px;
  left: 37px;
  top: 358px;
  background: #f4f4f5;
  border-radius: 20px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  color: #263238;
  transition: all 0.5s;
}

.parkingSpaceSelect {
  width: 80%;
  height: 225px;
  margin: auto;
  position: relative;
}

.parkingSpace {
  position: absolute;
  top: 50px;
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
}

.nextStepBtn {
  position: absolute;
  bottom: 40px;
  left: 10%;
  width: 80%;
  height: 40px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  background: #1f89eb;
  border-radius: 50px;
}

.disableItem {
  background: #ffffff;
  border: 1px solid #f4f4f5;
  color: #dbdbdb;
}

.selectedItem {
  background: #1f89eb;
  color: #ffffff;
}
</style>
