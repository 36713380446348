<template>
  <span class="radio">
    <input v-bind:id="genderType"  name="gender" type="radio">
    <label v-bind:for="genderType" class="radio-label">{{ gender }}</label>
  </span>
</template>
<script>
export default {
  props: ['gender', 'genderType'],
  data() {
    return {
    };
  },
};
</script>
<style scoped>
.radio {
  position: relative;
  display: inline-block;
  width: 75px;
  height: 20px;
  margin: auto;
}
.radio input[type="radio"] {
  position: absolute;
  right: 0;
  opacity: 0;
}
.radio-label{
  position: absolute;
  left: 0;
  font-size: 14px;
}
.radio input[type="radio"] + .radio-label:after {
  content: '';
  background: #f4f4f4;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 1em;
  height: 1em;
  position: relative;
  margin-left: 0.5em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
}
.radio input[type="radio"]:checked + .radio-label:after {
  background-color: #3197EE;
  box-shadow: inset 0 0 0 4px #f4f4f4;
}
.radio input[type="radio"]:focus + .radio-label:after {
  outline: none;
  border-color: #3197EE;
}
.radio input[type="radio"]:disabled + .radio-label:after {
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border-color: #b4b4b4;
  background: #b4b4b4;
}
.radio input[type="radio"] + .radio-label:empty:after {
  margin-right: 0;
}
</style>
