<template>
  <div style="width: 100%">
    <div class="container">
      <!-- 名字 -->
      <div class="nameAndGender">
        <p class="BarTitle">称呼：</p>
        <div class="NAGInput">
          <div class="nameInput">
            <input type="text" style=“border:none;outline:none;” v-model="name" @input="onInput">
          </div>
          <div class="genderSelect">
            <span style="padding-top: 10px" @click="selectGender(1)"><radio :gender="gender[0]" :genderType="gender[1]"/></span>
            <span style="padding-top: 10px" @click="selectGender(0)"><radio :gender="gender[2]" :genderType="gender[3]"/></span>
          </div>
        </div>
      </div>
      <!-- 手机号 -->
      <div class="phoneNumber">
        <p class="BarTitle">手机号码</p>
        <div class="phoneInput">
            <input  type="tel" min="0" inputmode="numeric" pattern="[0-9]*" style=“border:none;outline:none;” v-model="phone" @input="onInput">
          </div>
      </div>
      <!-- 车牌号 -->
      <div class="carNumber">
        <p class="BarTitle">车牌号码：</p>
        <car-number @getWhetherInput="getWhetherInput" @deliverCarNumber="deliverCarNumber"/>
      </div>
      <div v-show="WhetherInput" style="width: 100%; height: 230px;"></div>
      <div class="finishBtn" style="bottom: -30px;" @click="backToFirstStep">
        修改时间段
      </div>
      <div class="finishBtn" :class="{'disableItem': phone.length !=11 || name =='' || genderSelectedMutex || CarNumber[0].length != 7}" @click.once="makeAppointment">
        预约
      </div>
    </div>
  </div>
</template>
<script>
import Radio from '../../components/Radio.vue';
import CarNumber from './carNumber.vue';

export default {
  components: { Radio, CarNumber },
  data() {
    return {
      gender: ['先生', 'male', '女士', 'female'],
      WhetherInput: false,
      name: '',
      phone: '',
      genderSelected: '',
      genderSelectedMutex: true,
      Data: {},
      CarNumber: [],
    };
  },
  methods: {
    getWhetherInput(data) {
      this.WhetherInput = data;
    },
    selectGender(data){
      this.genderSelected = data;
      this.Data["owner_gender"] = this.genderSelected;
      this.genderSelectedMutex = false
      console.log(this.genderSelected);
    },
    backToFirstStep() {
      this.$emit('backToFirstStep', false);
    },
    onInput() {
      this.$forceUpdate();
    },
    deliverCarNumber(data) {
      /* this.Data["car_type"] = data[1] + ''; */
      this.Data["car_numer_perfix"] = data[0].slice(0, 2).join("");
      this.Data["car_numer_suffix"] = data[0].slice(2, 7).join("");
      this.CarNumber = data;
      console.log(this.CarNumber)
    },
    makeAppointment() {
      this.Data["owner_name"] = this.name;
      this.Data["phone_number"] = this.phone;
      if(this.phone.length != 11 || this.name =='' || this.genderSelectedMutex || this.CarNumber[0].length != 7){
        return;
      }else{
        this.$emit("sendAppointment", this.Data)
      }
      
    },
  },
};
</script>
<style scoped>
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    transition: all 0.5s;
  }
  .container {
    position: relative;
  }

  .BarTitle {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: #0e0e0e;
  }

  .nameAndGender{
    width: 80%;
    height: 100px;
    margin: auto;
    margin-bottom: 15px;
    position: relative;
  }

  .NAGInput{
    position: absolute;
    top: 35px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .nameInput{
    border: 1.2px solid hsl(200, 4%, 84%);
    border-radius: 40px;
    overflow: hidden;
    height: 40px;
    width: 45%;
    /* width: 160px; */
    font-style: normal;
    font-size: 16px;
    color: #0e0e0e;
    display: inline-block;
  }

  .nameInput input{
    position: relative;
    top: 4px;
    display: block;
    /* width: 130px; */
    width: 80%;
    height: 30px;
    margin: auto;
    border: none;
    background:none;
    outline:none;
    font-style: normal;
    font-size: 16px;
    color: #0e0e0e;
  }

  .nameInput input:focus{
    border:none;
    outline:none;
  }

  .genderSelect{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    height: 40px;
    width: 140px;
  }

  .phoneNumber{
    width: 80%;
    height: 100px;
    margin: auto;
    margin-bottom: 15px;
    position: relative;
  }

  .phoneInput{
    border: 1.2px solid hsl(200, 4%, 84%);
    border-radius: 40px;
    overflow: hidden;
    height: 40px;
    width: 100%;
    font-style: normal;
    font-size: 16px;
    color: #0e0e0e;
    display: inline-block;
    position: absolute;
    top: 35px;
  }

  .phoneInput input{
    position: relative;
    top: 4px;
    display: block;
    width: 90%;
    height: 30px;
    margin: auto;
    border: none;
    background:none;
    outline:none;
    font-style: normal;
    font-size: 16px;
    color: #0e0e0e;
  }

  .phonenput input:focus{
    border:none;
    outline:none;
  }

  .carNumber{
    width: 80%;
    height: 120px;
    margin: auto;
    margin-bottom: 15px;
    position: relative;
  }

  .finishBtn{
    position: absolute;
    bottom: -100px;
    left: 10%;
    width: 80%;
    height: 40px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 40px;
    text-align: center;
    color: #FFFFFF;
    background: #1F89EB;
    border-radius: 50px;
  }

  .disableItem{
    background: #FFFFFF;
    border: 1px solid #F4F4F5;
    color: #DBDBDB;
  }

</style>
