/* eslint-disable no-unused-expressions */
<template>
    <div style="position: absolute; top: 40px; width: 100%">
        <div class="carNumberShow">
            <div :class="{'inputIng': recordedNumberNum == null && startInput}" class="numberItem" @click="inputCarNumber">{{carNumber[0] ? carNumber[0] : ''}}</div>
            <div :class="{'inputIng': recordedNumberNum == 1}" class="numberItem" @click="inputCarNumber">{{carNumber[1] ? carNumber[1] : ''}}</div>
            <div class="point"></div>
            <div :class="{'inputIng': recordedNumberNum == 2}" class="numberItem" @click="inputCarNumber">{{carNumber[2] ? carNumber[2] : ''}}</div>
            <div :class="{'inputIng': recordedNumberNum == 3}" class="numberItem" @click="inputCarNumber">{{carNumber[3] ? carNumber[3] : ''}}</div>
            <div :class="{'inputIng': recordedNumberNum == 4}" class="numberItem" @click="inputCarNumber">{{carNumber[4] ? carNumber[4] : ''}}</div>
            <div :class="{'inputIng': recordedNumberNum == 5}" class="numberItem" @click="inputCarNumber">{{carNumber[5] ? carNumber[5] : ''}}</div>
            <div :class="{'inputIng': recordedNumberNum == 6}" class="numberItem" @click="inputCarNumber">{{carNumber[6] ? carNumber[6] : ''}}</div>
            <div class="numberItem" :class="{'newEnergy': newEnergy, 'disableItem': !newEnergy}" style="font-size: 8px" @click="newEnergySelect">新能源</div>
        </div>
        <div class="keyboard" v-show="startInput">
            <div class="provinceCon" v-show="!provinceRecorded">
                <span class="keyboardItem" v-for="(item, index) in province" :key="index" @click="selectProvince(index)">{{item}}</span>
                <span class="keyboardItem"><img style="width: 80%; position: relative; top: 15%;" src="../../static/img/cancel.png" alt="" @click="cancel"> </span>
            </div>
            <div class="numberAndLettersCon" v-show="provinceRecorded">
              <div class="numberAndLettersGrid">
                <span class="keyboardItem" v-for="(item, index) in number" :key="index" @click="selectNumber(index)">{{item}}</span>
              </div>
              <div class="numberAndLettersGrid" style="grid-template-columns: repeat(9, 1fr); grid-template-rows: repeat(3, 1fr); grid-row-gap: 5px;">
                <span class="keyboardItem" v-for="(item, index) in letters" :key="index" @click="selectLetters(index)">{{item}}</span>
                <span class="keyboardItem" style="grid-column: 7 / 10"><img style="height: 32px; position: relative; top: 15%;" src="../../static/img/cancel.png" alt="" @click="cancel"> </span>
              </div>
            </div>
        </div>
        <div class="filter" v-show="startInput" @click="inputCarNumber"></div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      province: ['京', '津', '冀', '晋', '蒙', '辽', '吉', '黑', '沪', '苏', '浙', '皖', '闽', '赣', '鲁', '豫', '鄂', '湘', '粤', '桂', '琼', '渝', '川', '贵', '云', '藏', '陕', '甘', '青', '宁', '新'],
      number: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
      letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      carNumber: [],
      recordedNumberNum: null,
      startInput: false,
      provinceRecorded: false,
      newEnergy: false,
    };
  },
  methods: {
    inputCarNumber() {
      this.startInput = !this.startInput;
      this.$emit('getWhetherInput', this.startInput);
    },
    newEnergySelect() {
      this.newEnergy = !this.newEnergy;
      this.deliverCarNumber();
    },
    selectProvince(index) {
      this.carNumber.push(this.province[index]);
      this.recordedNumberNum = 1;
      this.provinceRecorded = true;
      this.deliverCarNumber();
    },
    selectNumber(index) {
      this.carNumber.push(this.number[index]);
      this.recordedNumberNum++;
      this.recordedNumberNum == 7 ? this.inputCarNumber() : '';
      this.deliverCarNumber();
    },
    selectLetters(index) {
      this.carNumber.push(this.letters[index]);
      this.recordedNumberNum++;
      this.recordedNumberNum == 7 ? this.inputCarNumber() : '';
      this.deliverCarNumber();
    },
    deliverCarNumber() {
      this.$emit('deliverCarNumber' ,[this.carNumber, this.newEnergy ? 1 : 0])
    },
    cancel() {
      this.carNumber != [] ? this.carNumber.pop() : '';
      this.recordedNumberNum != null ? this.recordedNumberNum-- : '';
      this.carNumber.length == 1 ? this.provinceRecorded = false : '';
    },
  },
};
</script>
<style scoped>
  *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    transition: all 0.5s;
  }

  .point{
      position: relative;
      top: 18px;
      background: #09A6B5;
      height: 4px;
      width: 4px;
      overflow: hidden;
      border-radius: 50%;
  }

  .carNumberShow{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: 100%;
      height: 40px;
  }

  .numberItem{
      width: 30px;
      height: 40px;
      border: 1.2px solid #C4C4C4;
      font-family: PingFang SC;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      text-align: center;
      line-height: 40px;
      border-radius: 10px;
  }

  .disableItem{
    background: #FFFFFF;
    border: 1px solid #F4F4F5;
    color: #DBDBDB;
  }

  .newEnergy{
    color: #09A6B5;
    border: 1.2px solid #09A6B5;
  }

  .filter{
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      opacity: 0;
  }

  .inputIng{
    border: 1.2px solid #69eeff;
  }

  .keyboard{
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 250px;
      background-color: rgb(226, 226, 226);
      padding: 10px 5px;
      z-index: 99;
      font-family: PingFang SC;
      font-style: normal;
      font-size: 18px;
  }

  .provinceCon{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    grid-template-rows: 53.75px 53.75px 53.75px 53.75px;
  }

  .keyboardItem{
    border: 1px solid rgb(121, 121, 121);
    background-color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    text-align: center;
    line-height: 50px;
  }

  .numberAndLettersCon{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 3px;
    grid-row-gap: 5px;
    grid-template-rows: 1fr 3fr;/* 53.75px 53.75px 53.75px 53.75px */
  }

  .numberAndLettersGrid{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-column-gap: 3px;
    grid-template-rows: 1fr;
  }

  .numberAndLettersItem{
    border: 1px solid rgb(121, 121, 121);
    background-color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    text-align: center;
    line-height: 50px;
  }
</style>
